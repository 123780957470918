<template>
  <el-dialog :visible.sync="visible" width="800px" center>
    <div class="no-auth">
      <div class="head">{{ $t("el.authDialog.applicationTitle") }}</div>
      <div class="auth-data">
        <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="authList"
          border
          tooltip-effect="dark"
          style="width: 100%"
          align="center"
          :empty-text="$t('el.common.noData')"
        >
          <el-table-column
            prop="staffName"
            :label="$t('el.authDialog.name')"
            show-overflow-tooltip
            width="110px"
          ></el-table-column>
          <!-- <el-table-column
            :label="$t('el.authDialog.subjects')"
            show-overflow-tooltip
            width="110px"
          >
            <template slot-scope="scope">
              <span
                v-for="(item, index) in scope.row.subjectAuthList"
                :key="index"
              >
                {{ item.subjectName }}
                <span v-if="index != scope.row.subjectAuthList.length - 1"
                  >、</span
                >
              </span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('el.authDialog.grades')" show-overflow-tooltip>
            <template slot-scope="scope">
              <span
                v-for="(item, index) in scope.row.gradeAuthList"
                :key="index"
              >
                {{ item.gradeName }}
                <span v-if="index != scope.row.gradeAuthList.length - 1"
                  >、</span
                >
              </span>
            </template>
          </el-table-column> -->
          <el-table-column :label="$t('el.authDialog.courseNames')">
            <template slot-scope="scope">
              <tooltip-over
                :content="
                  scope.row.authCourseNames &&
                  scope.row.authCourseNames.join('、')
                "
                :singleAble="true"
              ></tooltip-over>
              <!-- <span
                v-for="(item, index) in scope.row.authCourseNames"
                :key="index"
              >
                {{ item }}
                <span v-if="index != scope.row.authCourseNames.length - 1"
                  >、</span
                >
              </span> -->
            </template>
          </el-table-column>
          <el-table-column
            prop="mobile"
            :label="$t('el.authDialog.telephone')"
            show-overflow-tooltip
            width="150px"
          ></el-table-column>
        </el-table>
      </div>
    </div>
    <div class="pagination">
      <xm-pagination
        :total="pageInfo.total"
        :params="pagingParams"
        @params-change="(params) => (pagingParams = params)"
      />
    </div>
  </el-dialog>
</template>
<script>
import { getQueryUserAuthInfo } from "@/api/home/index";
import tooltipOver from "@/components/biz-components/tooltipOver/index.vue";

export default {
  components: {
    tooltipOver,
  },
  data() {
    return {
      loading: false,
      pageInfo: {
        total: 10000,
      },
      authList: [
        // {
        //   name: "韩梅梅",
        //   sub: "语文",
        //   class: "一年级、二年级、三年级",
        //   tel: "13588888888"
        // },
        // {
        //   name: "韩梅梅",
        //   sub: "语文",
        //   class: "一年级、二年级、三年级",
        //   tel: "13588888888"
        // },
        // {
        //   name: "韩梅梅",
        //   sub: "语文",
        //   class: "一年级、二年级、三年级",
        //   tel: "13588888888"
        // },
        // {
        //   name: "韩梅梅",
        //   sub: "语文",
        //   class: "一年级、二年级、三年级",
        //   tel: "13588888888"
        // },
        // {
        //   name: "韩梅梅",
        //   sub: "语文",
        //   class: "一年级、二年级、三年级",
        //   tel: "13588888888"
        // },
        // {
        //   name: "韩梅梅",
        //   sub: "语文",
        //   class: "一年级、二年级、三年级",
        //   tel: "13588888888"
        // },
        // {
        //   name: "韩梅梅",
        //   sub: "语文",
        //   class: "一年级、二年级、三年级",
        //   tel: "13588888888"
        // },
        // {
        //   name: "韩梅梅",
        //   sub: "语文",
        //   class: "一年级、二年级、三年级",
        //   tel: "13588888888"
        // },
        // {
        //   name: "韩梅梅",
        //   sub: "语文",
        //   class: "一年级、二年级、三年级",
        //   tel: "13588888888"
        // },
        // {
        //   name: "韩梅梅",
        //   sub: "语文",
        //   class: "一年级、二年级、三年级",
        //   tel: "13588888888"
        // },
        // {
        //   name: "韩梅梅",
        //   sub: "语文",
        //   class: "一年级、二年级、三年级",
        //   tel: "13588888888"
        // }
      ],
      pagingParams: {
        limit: 5,
        offset: 0,
        page: 1,
      },
      visible: false,
    };
  },
  watch: {
    /**
     * 分页参数改变重新获取数据
     */
    pagingParams() {
      this.getData();
    },
  },
  computed: {},
  created() {},
  methods: {
    getData() {
      return getQueryUserAuthInfo(this.pagingParams)
        .then(({ data }) => {
          if (Array.isArray(data.records)) this.authList = data.records;
          console.log(this.authList, "this.authList");
          this.pageInfo.total = data.total || 0;
          this.pagingParams.pages = data.pages;
          this.pagingParams.size = data.size;
          this.pagingParams.total = data.total;
        })
        .catch(() => {});
    },
    init() {
      this.visible = true;
      this.getData();
    },
    /**
     * 获取新增或编辑参数
     */
    getConfigParams() {
      let sendData = Object.assign({}, this.ruleForm);
      return sendData;
    },
  },
};
</script>
<style lang="less" scoped>
.no-auth {
  margin: 0 auto;
  .head {
    font-size: 16px;
    font-weight: 500;
    color: #131313;
    // line-height: 24px;
    // margin-top: 3px;
    margin-bottom: 25px;
    text-align: left;
  }
  .auth-data {
    margin-bottom: 25px;
  }
  /deep/ .el-tooltip__popper {
    position: absolute;
    border-radius: 4px;
    padding: 10px;
    z-index: 2000;
    font-size: 12px;
    line-height: 1.2;
    min-width: 10px;
    word-wrap: break-word;
    max-width: 500px;

    box-sizing: border-box;
  }
}
/deep/ .el-dialog__body {
  padding: 4px 25px 30px;
}
/deep/ .el-dialog {
  top: 40% !important;
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  border-radius: 10px;
  transform: translate(-50%, -50%) !important;
}
.pagination {
  text-align: center;
}
/deep/.el-pagination.is-background .btn-prev {
  background-color: #fff;
  border-radius: 50%;
}
/deep/ .el-pagination.is-background .el-pager li {
  background-color: #fff;
}
/deep/ .el-pager li {
  background-color: #fff !important;
  border: 1px solid #dcdddd;
}
/deep/ .el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background-color: #fff !important;
  border: 1px solid #dcdddd;
  border-radius: 50%;
}
/deep/.el-pagination__total {
  font-size: 14px !important;
  // font-family: "MicrosoftYaHei"!important;
  color: #999 !important;
}
/deep/.el-pagination.is-background .btn-next,
/deep/.el-pagination.is-background .btn-prev,
/deep/.el-pagination.is-background .el-pager li {
  background-color: #fff !important;
  border: 1px solid #dcdddd;
  border-radius: 50%;
  color: #999;
}
/deep/.el-pager li.active {
  background-color: #6049ff !important;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #6049ff !important;
}
/deep/.el-pagination .btn-prev .el-icon {
  color: #999;
  font-size: 14px;
}
/deep/.el-pagination__total {
  color: #999 !important;
}
/deep/.el-pagination .btn-next .el-icon,
/deep/.el-pagination .btn-prev .el-icon {
  font-size: 14px;
  color: #999;
}
/deep/.el-pagination__total {
  font-size: 14px !important;
  color: #999 !important;
}
</style>

<style lang="less">
.el-tooltip__popper {
  position: absolute;
  border-radius: 4px;
  padding: 10px;
  z-index: 2000;
  font-size: 12px;
  line-height: 1.2;
  min-width: 10px;
  word-wrap: break-word;
  max-width: 500px;

  box-sizing: border-box;
}
</style>
