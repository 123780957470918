<template>
  <div class="details-container">
    <el-tabs v-model="activeName" style="height: 100%">
      <!-- <el-tab-pane :label="$t('el.courseDetails.details')" name="first">
        <v-html v-if="htmlText" :htmlText="htmlText"></v-html>
        <div v-else style="text-align: center; padding-top: 40px">
          <img
            class="no-img"
            src="@/assets/images/subjectType/pic_empty_course@2x.png"
            :alt="$t('el.courseDetails.centerName')"
            :title="$t('el.courseDetails.centerName')"
          />
          <div>{{$t('el.common.noIntroduction')}}</div>
        </div>
      </el-tab-pane> -->
      <el-tab-pane
        :label="$t('el.courseDetails.content')"
        name="second"
        class="details-catalog"
      >
        <div v-if="allowed === 0" class="no-allewed-content">
          <span class="no-allewed-text">{{
            $t("el.courseDetails.noPermissionCourse")
          }}</span>
          <!-- <div class="no-allewed-btn" @click="showAuth">{{$t('el.courseDetails.applicationCourse')}}</div> -->
        </div>
        <div v-if="knowledgeList.length > 0">
          <div
            v-for="(item, index) in knowledgeList"
            class="details-chapter"
            :key="item.id"
          >
            <div class="flex flex-m details-chapter-header">
              <el-image
                class="details-chapter-icon"
                :src="iconChapter"
                fit="fit"
              ></el-image>
              <span class="details-chapter-title">{{
                item.knowledgeName
              }}</span>
            </div>
            <div v-for="obj in item.children" :key="obj.id">
              <div class="flex flex-m details-section">
                <span
                  v-check-bsk-permission="{
                    handler: prepareBack,
                    handlerArgs: [obj],
                    args: {
                      courseId: courseId,
                      originTenantId: originTenantId,
                    },
                    checkRequestStatus: 'isRequestPending',
                    noInnerChangePending: true,
                  }"
                  >{{ obj.knowledgeName }}</span
                >
                <span
                  v-if="obj.isTeacherTrain"
                  class="details-section-instructions"
                  >师训</span
                >
                <div
                  v-if="allowed && (!isTry || (isTry && obj.idx < 3))"
                  class="flex details-section-btn"
                >
                  <div
                    v-check-bsk-permission="{
                      handler: prepareBack,
                      handlerArgs: [obj],
                      args: {
                        courseId: courseId,
                        originTenantId: originTenantId,
                      },
                      checkRequestStatus: 'isRequestPending',
                      noInnerChangePending: true,
                    }"
                    class="details-section-btn-bk"
                  >
                    {{ $t("el.courseDetails.prepareLessons") }}
                  </div>
                  <div
                    class="details-section-btn-sk"
                    :class="[
                      obj.standardCount + obj.personCount < 1
                        ? 'disabled-class'
                        : '',
                    ]"
                    v-check-bsk-permission="{
                      handler: give,
                      handlerArgs: [obj],
                      args: {
                        courseId: courseId,
                        originTenantId: originTenantId,
                      },
                      checkRequestStatus: 'isRequestPending',
                      noInnerChangePending: true,
                    }"
                  >
                    {{ $t("el.courseDetails.teachingLessons") }}
                  </div>
                </div>
                <el-image
                  class="details-section-lock"
                  v-if="isTry && obj.idx > 2"
                  :src="iconLock"
                ></el-image>
              </div>
            </div>
            <div
              v-if="index !== knowledgeList.length - 1"
              class="dash-line"
            ></div>
          </div>
        </div>
        <div
          v-else-if="knowledgeList.length === 0"
          style="text-align: center; padding-top: 40px"
        >
          <img
            class="no-img"
            src="@/assets/images/subjectType/pic_empty_course@2x.png"
            :alt="$t('el.courseDetails.centerName')"
            :title="$t('el.courseDetails.centerName')"
          />
          <div>{{ $t("el.common.noData") }}</div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <AuthDialog v-if="dialogAuth" ref="authConfig"></AuthDialog>
  </div>
</template>

<script>
import VHtml from "../../../components/global/VHtml/index.vue";
import AuthDialog from "@/views/myCourseList/authDialog.vue";
import { mapGetters, mapState } from "vuex";
import { checkAvailability } from "@/api/courseList/index";
export default {
  data() {
    return {
      iconChapter: require("../../../assets/images/icon/knowledge-title.png"),
      iconLock: require("@/assets/images/icon/icon-lock.png"),
      activeName: "second",
      dialogAuth: false,
      isRequestPending: false,
      courseId: "",
      originTenantId: "",
      originTenantType: "",
    };
  },
  props: {
    knowledgeList: {
      type: Array,
      default: () => [{}],
    },
    htmlText: {
      type: String,
      default: "",
    },
    allowed: {
      type: Number,
      default: -1,
    },
    // 是否是试用课程
    isTry: {
      type: Number,
      default: 0,
    },
    courseTenantInfoId: {
      type: String,
    },
  },
  components: {
    // VHtml,
    AuthDialog,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  mounted() {
    this.courseId = this.$route.query.id || "";
    this.originTenantId = this.$route.query.originTenantId || "";
    this.originTenantType = this.$route.query.originTenantType || "";
  },
  methods: {
    showAuth() {
      this.getAuthList();
    },
    getAuthList() {
      this.dialogAuth = true;
      this.$nextTick(() => {
        this.$refs.authConfig && this.$refs.authConfig.init();
      });
    },
    prepareBack(data) {
      // this.$router.push({
      //   path: '/classroom/bk-ja',
      //   query: {id: data.id + ''}
      // })
      if (this.isTry && data.idx > 2) {
        this.$message.warning("试用课程仅可使用前三个知识点！");
        return;
      }

      if (this.user.officeType === 1) {
        checkAvailability({ courseTenantInfoId: this.courseTenantInfoId })
          .then((res) => {
            this.$utils.openWindow(
              `hdClassroomWps/bk-kj?id=${data.id}&courseId=${
                this.courseId || ""
              }&originTenantId=${this.originTenantId || ""}&originTenantType=${
                this.originTenantType || ""
              }&courseTenantInfoId=${this.courseTenantInfoId || ""}`
            );
          })
          .finally(() => {
            setTimeout(() => {
              this.isRequestPending = false;
            }, 300);
          });
        //   .catch((err) => {
        //     this.$message.error("当前非可用时段，不可进行备课/授课");
        //   });
      } else {
        this.$utils.openWindow(
          `hdClassroom/bk-kj?id=${data.id}&courseId=${
            this.courseId || ""
          }&originTenantId=${this.originTenantId || ""}&originTenantType=${
            this.originTenantType || ""
          }&courseTenantInfoId=${this.courseTenantInfoId || ""}`
        );
      }
    },
    give(item) {
      // console.log("item:", item);
      if (item.standardCount + item.personCount < 1) {
        // console.log("item.standardCount", item.standardCount);
        return;
      } else {
        // this.$emit('giveBack', item,this.courseId)
        checkAvailability({ courseTenantInfoId: this.courseTenantInfoId })
          .then((res) => {
            this.$router.push({
              path: "/hdTeaching",
              query: {
                id: item.id,
                from: "hdCourseDetails",
                courseId: this.courseId || "",
                originTenantId: this.originTenantId || "",
                originTenantType: this.originTenantType || "",
                courseTenantInfoId: this.courseTenantInfoId,
              },
            });
          })
          .finally(() => {
            setTimeout(() => {
              this.isRequestPending = false;
            }, 300);
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.details-container {
  min-height: 551px;
  background: #ffffff;
  border-radius: 6px;
  padding: 20px 31px;

  .no-img {
    width: 420px;
    height: 240px;
  }

  .details-catalog {
    min-height: 496px;
  }

  .no-allewed {
    &-content {
      width: 100%;
      height: 100%;
      position: absolute;
      text-align: center;
      padding-top: 200px;
      top: 0;
      //   background: #131313;
      background-image: linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.6) 1%,
        rgba(255, 255, 255, 0.8) 10%,
        rgba(255, 255, 255, 1) 100%
      );

      //    rgba(255, 255, 255, 0) 0%,
      //     rgba(255, 255, 255, 0.2) 1%,
      //      rgba(255, 255, 255, 0.4) 10%,
      //      rgba(255, 255, 255, 0.6) 1%,
      //      rgba(255, 255, 255, 0.8) 10%,
      //     rgba(255, 255, 255, 1) 100%,
    }
    &-text {
      width: fit-content;
      height: 16px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-left: auto;
      margin-right: auto;
    }
    &-btn {
      width: 120px;
      height: 34px;
      background: #5a53f5;
      box-shadow: 0px 4px 5px 0px rgba(90, 83, 245, 0.19);
      border-radius: 17px;

      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 34px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin-top: 18px;
      cursor: pointer;
    }
  }

  .details-chapter {
    &-header {
      margin-bottom: 14px;
    }
    &-icon {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
    &-title {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #131313;
      line-height: 28px;
      margin-left: 16px;
    }
  }
  .details-section {
    box-sizing: border-box;
    padding-left: 74px;

    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    margin-bottom: 13px;
    span:hover {
      cursor: pointer;
      color: @themeBlue;
    }

    // 师训
    &-instructions {
      padding: 3px 12px;
      background: rgba(90, 83, 245, 0.15);
      border-radius: 12px;

      font-size: 12px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #5a53f5;
      margin-left: 14px;
    }

    &-lock {
      width: 16px;
      height: 16px;
      margin-right: 64px;
      margin-left: auto;
    }

    &-btn {
      margin-left: auto;
      margin-right: 28px;
      flex-shrink: 0;
      &-bk {
        width: fit-content;
        height: 24px;
        background: #f1f4fe;
        border-radius: 12px;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #5a53f5;
        line-height: 24px;
        text-align: center;
        cursor: pointer;
        margin-right: 20px;
      }
      &-sk {
        width: fit-content;
        height: 24px;
        background: #fcead1;
        border-radius: 12px;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #eea642;
        line-height: 24px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  .disabled-class {
    color: rgba(212, 212, 212, 1) !important;

    font-size: 12px;
    font-family: PingFang SC;
    color: #5a53f5;
    line-height: 24px;
    cursor: inherit;
  }
  .dash-line {
    margin-left: 42px;
    margin-right: 42px;
    margin-bottom: 14px;
    height: 1px;
    // scale: 0.5;
    background: #ededed;
  }
  /deep/ .el-tabs__nav-wrap::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: inherit;
    z-index: 1;
  }
  /deep/ .el-tabs__item {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #131313;
  }
  /deep/ .el-tabs__active-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    background-color: #ff9e10;
    border-radius: 2px;
    z-index: 1;
    -webkit-transition: -webkit-transform 0.3s
      cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    list-style: none;
  }
  /deep/ .cell {
    text-align: center;
  }
  /deep/.el-table th {
    background-color: rgba(245, 246, 247, 1) !important;
  }
  .pagination {
    margin: 0 auto;
    text-align: center;
  }
  ::-webkit-scrollbar {
    display: none !important; /* Chrome Safari */
  }
  /deep/ .el-pager li {
    height: 30px;
    // line-height: 30px;
    width: 30px;
    min-width: 30px;
    border-radius: 50% !important;
    padding: 0 0px;
    margin: 0 5px;
    font-size: 13px;
    /* font-family:  SourceHanSansCN-Medium ,  SourceHanSansCN ; */
    font-weight: 400;
    color: rgba(40, 40, 40, 1);
  }
  /deep/ .el-pager li.active {
    background: @themeBlue!important;
    color: #fff;
    font-size: 13px !important;
    /* font-family:  SourceHanSansCN-Medium ,  SourceHanSansCN !important; */
  }
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: @themeBlue;
  }
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
    color: #fff !important;
  }
  /deep/.el-pagination__total {
    font-size: 12px;
    /* font-family:  MicrosoftYaHei !important; */
    color: rgba(40, 40, 40, 1);
  }
  /deep/.el-pagination button {
    height: 30px !important;
    line-height: 30px !important;
  }
  /deep/ .el-pagination button:hover {
    color: @themeBlue !important;
  }
  /deep/ .el-table th.gutter {
    display: table-cell !important;
  }
  /deep/ .el-table--enable-row-transition .el-table__body td {
    max-width: 100px;
  }
}
</style>
