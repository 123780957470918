<template>
  <!-- 查询表单、列表、分页高度封装插件 -->
  <div v-html="htmlContent" class="html-box"></div>
</template>

<script>
import Vue from "vue";

export default {
  name: "",
  components: {},
  props: {
    /**
     * 富文本
     */
    htmlText: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      htmlContent: "",
      Vue, // Vue
    };
  },
  computed: {},
  watch: {
    htmlText: {
      deep: true,
      immediate: true,
      handler: function (newVal) {
        this.htmlContent = this.richesHandle(newVal);
      },
    },
  },
  created() {},
  methods: {
    richesHandle(data) {
      // console.log('data1:', data);
      let fnResult = data;
      if (data) {
        console.log("data:", data);
        fnResult = fnResult.replace(/()/g, "");
        fnResult = fnResult.replace(/&nbsp;/g, "\xa0");
        fnResult = fnResult.replace(/(&nbsp)/g, "");
        fnResult = fnResult.replace(
          <html>
            <head>
              <title></title>
            </head>
            <body>),""); fn_result = fn_result.replace((</body>
          </html>,
          ""
        );
      }

      return fnResult;
    },
  },
};
</script>

<style scoped>
.html-box {
  color: #000000;
}
/* .content .html-box >>> img {

} */
.content .html-box >>> p {
  max-width: 100%;
}
.content .html-box >>> span {
  max-width: 100%;
}
.content .html-box >>> table {
  max-width: 100%;
}
</style>
